<template>
  <section class="l-content full relative">
    <div class="flex" style="height:100%">
      <div style="width:250px;">
        <l-select v-model="selGroupId" :data="groupList" :prop="['groupId', 'groupName']" @change="groupChange"></l-select>
        <el-card class="sm">
          <div slot="header" class="clearfix">
            <span>列表</span>
          </div>
        </el-card>
      </div>
      <div class="flex-sub">
        <l-amap1 ref="map" @complete="mapComplete"></l-amap1>
      </div>
    </div>
    <!-- <l-absolute :width="1000">
      <l-select v-model="selGroupId" :data="groupList" :prop="['groupId', 'groupName']" @change="groupChange"></l-select>
      <el-button class="margin-left" type="warning" @click="addGroup()" icon="el-icon-circle-plus-outline">新增画布</el-button>
      <el-button v-if="selGroupId" class="margin-left" type="primary" @click="editGroup()" icon="el-icon-edit">编辑画布</el-button>
      <div style="width:205px;margin-top:10px;">
        <el-card class="sm">
          <div slot="header" class="clearfix">
            <span>列表</span>
          </div>
        </el-card>
      </div>
    </l-absolute> -->
  </section>
</template>

<script>
import { mapState } from 'vuex'
import LAmap1 from '@/lgh/components/amap1/'
import groupAddoredit from './_addoredit_areagroup'
export default {
  computed: {
    ...mapState(['userInfo', 'setting'])
  },
  name: 'CarMonitor',
  components: { 'l-amap1': LAmap1 },
  data() {
    return {
      selGroupId: null,
      groupList: null,
      mapStyle: 'amap://styles/blue',
      mapCenter: [118.787975, 31.983388],
      monitorData: null,
      condition: { groupId: null },
      markerList: []
    }
  },
  created() {
    this.getGroupList()
  },
  methods: {
    async getGroupList() {
      this.groupList = await this.$lgh.get('api/enterprise/easset/areagroup/list', ['enterpriseCode'])
    },
    groupChange() {
      if (this.selGroupId) {
        this.getGroupDetail(this.selGroupId)
      }
    },
    getGroupDetail(groupId, cb) {
      this.$lgh.get('api/enterprise/easset/areagroup/detail', { groupId: groupId }).then(res => {
        cb ? cb(res) : this.setGroupToMap(res)
      })
    },
    async addGroup() {
      var r = await this.$lgh.openDialog({
        ins: groupAddoredit,
        title: '新增区域画布',
        data: {
          dataInfo: {
            enterpriseCode: this.userInfo.enterpriseCode,
            groupType: 1,
            imgUrl: null
          }
        }
      })
      var bounds = this.$refs.map.getImageLayerSetting(r.width, r.height)
      r.swLng = bounds[0].lng
      r.swLat = bounds[0].lat
      r.neLng = bounds[1].lng
      r.neLat = bounds[1].lat
      await this.$lgh.post('api/enterprise/easset/areagroup/addoredit', r)
      this.$lgh.toastSuccess('新增成功')
      this.getGroupList()
    },
    editGroup() {
      this.getGroupDetail(this.selGroupId, async res => {
        var r = await this.$lgh.openDialog({
          ins: groupAddoredit,
          title: '修改区域画布',
          data: {
            dataInfo: res
          }
        })
        await this.$lgh.post('api/enterprise/easset/areagroup/addoredit', r)
        this.$lgh.toastSuccess('修改成功')
        this.getGroupList()
      })
    },
    setGroupToMap(group) {
      this.$refs.map.setGroupToMap(group)
    },
    mapComplete(map) {},
    mapRended(map) {
      // map.setFitView()
    },
    mapMarkerClick(data) {
      if (data && data.carId) {
        this.$site.view_car_detail(data.carId)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.m_block {
  position: relative;
  .brandlogo {
    position: absolute;
    right: 8px;
    top: 0px;
    height: 80px;
    width: 80px;
  }
}
</style>
