<template>
  <el-dialog v-if="dataInfo" :title="title" @closed="_closed" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="top5" top="5vh">
    <el-form ref="dataInfo" :model="dataInfo" :rules="dataRules" label-width="120px">
      <el-row class="padding" style="padding-bottom:0">
        <el-form-item label="区域名称" prop="groupName">
          <el-input v-model="dataInfo.groupName" autocomplete="off" placeholder="请输入区域名称"></el-input>
        </el-form-item>
        <el-form-item label="区域底图" prop="imgUrl">
          <l-upload @success="uploadSuccess" url="api/enterprise/easset/areagroup/img_uplload">
            <el-button type="primary" size="small" icon="el-icon-picture">图片上传</el-button>
          </l-upload>
          <div class="margin-top-mini" v-if="dataInfo.imgUrl">
            <el-image :src="dataInfo.imgUrl"></el-image>
          </div>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      eSeachLoading: false,
      eSeachList: [],
      dataRules: {
        groupName: [{ required: true, trigger: 'blur', message: '区域名称不能为空' }],
        imgUrl: [{ required: true, trigger: 'blur', message: '图片不能为空' }]
      }
    }
  },
  methods: {
    _closed() {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle() {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploadSuccess(res) {
      this.dataInfo.imgUrl = res.url
      this.dataInfo.width = res.width
      this.dataInfo.height = res.height
    },
    save() {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
